import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import {
    Alert,
    Box,
    Checkbox,
    Chip,
    Collapse,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {makeStyles} from "@mui/styles";
import {connect} from "formik";
import {groupBy} from "lodash";
import React, {useContext, useEffect, useState} from "react";
import TagAutocomplete from "./TagAutocomplete";
import moment from "moment";
import {BookingPriceContext, DrawerContext, SnackContext} from "../App";
import axios from "../utils/axios";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        guestWrapper: {
            background: "#fcfcfc",
            //   boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            border: "1px solid rgba(0, 0, 0, 0.06)",
        },
        image: {
            width: "100%",
            height: "150px",
            borderRadius: 2,
            objectFit: "cover",
            // [theme.breakpoints.down('sm')]: {
            // }
        },
    }),
    {name: "Step3"}
);

const getValuesForValidation = (values) => {
    const arrivalString = moment(values.arrival_date).format("YYYY-MM-DD");
    const departureString = moment(values.departure_date).format("YYYY-MM-DD");

    let data = {
        arrival_date: arrivalString,
        departure_date: departureString,
        destination: values.location,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        country: values.country,
        city: values.city,
        address: values.address,
        id_or_passport: values.id_or_passport,
        emergency_name: values.emergency_name,
        emergency_email: values.emergency_email,
        emergency_phone: values.emergency_phone,
    };

    let guests = [...values.guests];
    guests = JSON.parse(JSON.stringify(guests));
    guests.forEach((g) => {
        delete g.id;
        delete g.addons;
        delete g.roomId;
    });

    let rooms = [];
    let roomIds = [];
    let rGuests = [...values.guests];
    rGuests = JSON.parse(JSON.stringify(rGuests));
    rGuests.forEach((g) => {
        if (!roomIds.includes(g.roomId)) {
            roomIds.push(g.roomId);
        }
    });

    roomIds.forEach((rId) => {
        const arr = [];

        rGuests.forEach((g) => {
            if (g.roomId === rId) {
                arr.push(g);
            }
        });
        const obj = {room: rId, guests: arr};
        rooms.push(obj);
    });

    data = {
        ...data,
        guests: JSON.stringify(guests),
        rooms: JSON.stringify(rooms),
        approve_booking: values.approve_booking,
        register_confirm: values.register_confirm,
        tc_confirm: values.tc_confirm,
        tax_confirm: values.tax_confirm,
    };

    return data;
};

const Room = ({
                  notes,
                  room,
                  price,
                  off_season_price,
                  formik,
                  filteredGuests,
                  onGuestChanged,
                  addons,
                  guestsByRoom,
                  isOff,
                  selectedLocation,
              }) => {
    const classes = useStyles({});
    const [tabValue, setTabValue] = useState(0);
    const [guestsInRoom, setGuestsInRoom] = useState([]);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const {setDrawerOpen} = useContext(DrawerContext);

    setDrawerOpen(true);

    useEffect(() => {
        const inRoom = guestsByRoom[room._id];

        if (inRoom) {
            setGuestsInRoom(inRoom);
        } else {
            setGuestsInRoom([]);
        }
    }, [formik]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormikGuestValue = (addedGuests) => {
        addedGuests.forEach((aG) => {
            const addedGuestIndexInValues = formik.values.guests.findIndex(
                (g) => g.id === aG.id
            );

            const addedGuestValueRoomIdName = `guests[${addedGuestIndexInValues}].roomId`;

            formik.setFieldValue(addedGuestValueRoomIdName, room._id, true);
        });
    };

    const renderAddons = (guest) => {
        const guestIndexInValues = formik.values.guests.findIndex(
            (g) => g.id === guest.id
        );

        const guestValueAddonsName = `guests[${guestIndexInValues}].addons`;
        let currentAddons = [];

        currentAddons = formik.values.guests[guestIndexInValues].addons;

        return addons.map((addon) => {
            return (
                <FormGroup key={addon.addon._id}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={
                                    currentAddons && currentAddons.includes(addon.addon._id)
                                }
                            />
                        }
                        label={addon.addon.title + " / " + addon.price + " EUR (" + addon.addon.details + ")"}
                        name={addon.addon.title}
                        onChange={(e, v) => {
                            if (v) {
                                currentAddons.push(addon.addon._id);
                                currentAddons = currentAddons.filter((ao) => true);
                            } else {
                                const filtered = currentAddons.filter(
                                    (cA) => cA != addon.addon._id
                                );
                                currentAddons = filtered;
                            }
                            formik.setFieldValue(guestValueAddonsName, currentAddons, true);
                        }}
                    />
                </FormGroup>
            );
        });
    };

    const renderRoomGuests = () => {
        return (
            <Box sx={{width: "100%"}}>
                <Typography variant="subtitle2">Select addons</Typography>
                <Box display={"flex"}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}} flex={1} mr={3}>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {guestsInRoom.map((g) => (
                                <Tab label={`${g.first_name} ${g.last_name}`} key={g.id}/>
                            ))}
                        </Tabs>
                    </Box>
                </Box>
                {guestsInRoom.map((g, i) => {
                    return (
                        <TabPanel value={tabValue} index={i} key={i}>
                            {renderAddons(g)}
                        </TabPanel>
                    );
                })}
            </Box>
        );
    };

    return (
        <Box key={room._id} style={{position: "relative"}}>
            <Box
                mb={3}
                p={2}
                className={classes.guestWrapper}
                borderRadius={"3px"}
                key={room.name}
                display="flex"
                flexDirection="column"
            >
                <Box
                    className={classes.guestWrapper}
                    p={1}
                    mb={2}
                    bgcolor={"#006a12"}
                    borderRadius={"3px"}
                >
                    {isOff && selectedLocation && (
                        <Typography variant="caption" mt={1} color={"White"}>
                            Discounted price from {selectedLocation.off_season_start} until{" "}
                            {selectedLocation.off_season_end}.
                        </Typography>
                    )}
                </Box>

                <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        mb={isSmallScreen ? 3 : 0}
                    >
                        <img
                            alt={room.title}
                            src={room.image || "/noimage.png"}
                            className={classes.image}
                        />

                        {notes && (
                            <Box display="flex" alignItems="center" mt={2}>
                                <NoteAltOutlinedIcon mr={1} color="#757575"/>
                                <Typography
                                    variant="body1"
                                    style={{
                                        textTransform: "uppercase",
                                        fontSize: 12,
                                        color: "#757575",
                                    }}
                                >
                                    {notes}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Box ml={2} flex={4}>
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="h6">{room.title}</Typography>

                            <Box display="flex" flexDirection="column">
                                <Chip
                                    label={`${price} EUR / week `}
                                    color="secondary"
                                    style={{color: "white", fontWeight: "bold"}}
                                />

                                <Chip
                                    label={`Discounted price: ${off_season_price} EUR / week `}
                                    bgColor={"#e8e8e8"}
                                    style={{color: "#757575", fontWeight: "bold", marginTop: "10px"}}
                                />
                            </Box>
                        </Box>

                        <Typography variant="body1">{room.details}</Typography>

                        <Box mt={2}>
                            <TagAutocomplete
                                autocompleteId={`autocomplete-${room._id}`}
                                options={filteredGuests || []}
                                disabled={
                                    room.max_guests && guestsInRoom.length === room.max_guests
                                }
                                onGuestAdded={(addedGuests) => {
                                    const current = [...filteredGuests];
                                    const removedAdded = current.filter(
                                        (x) => !addedGuests.includes(x)
                                    );
                                    onGuestChanged(removedAdded);
                                    handleFormikGuestValue(addedGuests);
                                }}
                                onGuestRemoved={(removed) => {
                                    const g = [...filteredGuests];
                                    g.push(removed);
                                    onGuestChanged(g);

                                    const removedGuestIndexInValues =
                                        formik.values.guests.findIndex((g) => g.id === removed.id);

                                    const removedGuestValueRoomIdName = `guests[${removedGuestIndexInValues}].roomId`;

                                    formik.setFieldValue(removedGuestValueRoomIdName, null, true);
                                }}
                                defaultValue={guestsInRoom}
                            />

                            {room.max_guests && guestsInRoom.length === room.max_guests && (
                                <Box display="flex" alignItems="center" mt={2}>
                                    <Box mr={0.5}>
                                        <InfoOutlinedIcon
                                            style={{color: "rgba(0, 0, 0, 0.38)"}}
                                        />
                                    </Box>
                                    <FormHelperText disabled style={{marginTop: 0}}>
                                        Cannot add more guests to this room. Please select other
                                        room.
                                    </FormHelperText>
                                </Box>
                            )}
                        </Box>

                        <Box mt={4}>{guestsInRoom.length > 0 && renderRoomGuests()}</Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const Step3 = ({formik, selectedLocation}) => {
    const [filteredGuests, setFilteredGuests] = useState(null);
    const [addons, setAddons] = useState([]);
    const [guestsByRoom, setGuestsByRoom] = useState({});
    const [warningOpen, setWarningOpen] = useState(true);
    const [isOffSeason, setIsOffSeason] = useState(false);
    const {setBookingPrices} = useContext(BookingPriceContext);
    const {setIsSnackOpen, setMsg} = useContext(SnackContext);

    const calculate = async () => {
        try {
            let formData = new FormData();
            let stepData = getValuesForValidation(formik.values);

            Object.keys(stepData).forEach((key) => formData.append(key, stepData[key]));
            const response = await axios({
                method: "post",
                url: `/summary`,
                data: formData,
                headers: {"Content-Type": "application/json"},
            });

            if (response.data.data && !response.data.data.hasError) {
                setBookingPrices(response.data.data);
            } else {
                const errors = response.data.data.errors;
                let fieldNames = "";

                Object.keys(errors).map((key) => (fieldNames += ` ${key}`));
                setIsSnackOpen(true);
                setMsg("Please check these fields: " + fieldNames);
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        if (
            selectedLocation &&
            selectedLocation.off_season_start &&
            selectedLocation.off_season_end
        ) {
            const isOff = moment().isBetween(
                selectedLocation.off_season_start,
                selectedLocation.off_season_end,
                undefined,
                "()"
            );

            setIsOffSeason(isOff);
        }
    }, [selectedLocation]);

    useEffect(() => {
        window.scrollTo({top: 500, left: 0, behavior: "smooth"});
    }, []);

    useEffect(() => {
        setAddons(formik.values.addons);

        const groupedByRoom = groupBy(formik.values.guests, function (n) {
            return n.roomId;
        });
        setGuestsByRoom(groupedByRoom);
    }, [formik]);

    useEffect(() => {
        let guests = [...formik.values.guests];
        guests = JSON.parse(JSON.stringify(guests));

        const arr = [];
        guests.forEach((g) => {
            if (!g.roomId) {
                arr.push(g);
            }
        });

        setFilteredGuests(arr);
    }, [formik.values.guests]);

    useEffect(() => {
        calculate();
    }, [formik.values]);

    useEffect(() => {
        if (filteredGuests) {
            formik.setFieldValue("filteredGuestsLength", filteredGuests.length);
        }
    }, [filteredGuests]);

    return (
        <Box>
            {warningOpen && formik.errors.filteredGuestsLength && (
                <Box mb={2}>
                    <Collapse in={warningOpen}>
                        <Alert
                            severity="warning"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setWarningOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {formik.errors.filteredGuestsLength}
                        </Alert>
                    </Collapse>
                </Box>
            )}

            <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h5" mr={1}>
                    Rooms
                </Typography>
            </Box>

            {formik.values.rooms.map((room) => {
                return (
                    <Room
                        notes={room.notes}
                        room={room.room}
                        price={room.price}
                        off_season_price={room.off_season_price}
                        formik={formik}
                        filteredGuests={filteredGuests}
                        onGuestChanged={(g) => setFilteredGuests(g)}
                        key={room.room._id}
                        addons={addons}
                        guestsByRoom={guestsByRoom}
                        isOff={isOffSeason}
                        selectedLocation={selectedLocation}
                    />
                );
            })}
        </Box>
    );
};

export default connect(Step3);

import {Divider, Drawer, Box, Typography, ListSubheader, Chip} from "@mui/material";
import {List, ListItem, ListItemText, ListItemButton, ListItemIcon} from "@mui/material";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import React, {useContext} from "react";
import {BookingPriceContext, DrawerContext} from "../App";

const SummaryDrawer = () => {
    const {isDrawerOpen} = useContext(DrawerContext);
    const {bookingPrices} = useContext(BookingPriceContext);
    const drawerWidth = 240;

    return (
        <Drawer open={isDrawerOpen}
                variant="temporary"
                hideBackdrop="true"
                anchor="right"
                classes="p-10"
                disableScrollLock="true"
                ModalProps={{
                    keepMounted: false,
                    hideBackdrop: true,
                    disableScrollLock: true,
                    disableAutoFocus: true
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}>
            <Typography variant="h6" sx={{padding: 1}}>Summary</Typography>
            <Typography variant="p" sx={{padding: 1}}>({bookingPrices.timeBlock})</Typography>
            <Divider/>
            <Box>
                {bookingPrices.rooms.map((room, index) => {
                    return (
                        <List aria-labelledby="nested-list-subheader"
                              subheader={
                                  <ListSubheader component="div" id="nested-list-subheader" sx={{backgroundColor: "#ea5b3a", color: "#ffffff"}}>
                                      {room.title} {room.priceFormatted}
                                  </ListSubheader>
                              }>

                            {room.guests.map((guest, guestIndex) => {
                                return (
                                    <ListItem>
                                        <List aria-labelledby="nested-list-subheader"
                                              subheader={
                                                  <ListSubheader component="div" id="nested-list-subheader" sx={{color: "#ea5b3a"}}>
                                                      {guest.name}
                                                  </ListSubheader>
                                              }>
                                            {guest.addons.map((addon, addonIndex) => {
                                                return (
                                                    <ListItem sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}><Typography variant="caption" sx={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        flexGrow: 1
                                                    }}>{addon.title}</Typography><Chip
                                                        sx={{flexShrink: 1, float: 'right'}}
                                                        label={addon.priceFormatted}></Chip></ListItem>
                                                )
                                            })}
                                        </List>
                                        <Divider/>
                                    </ListItem>
                                )
                            })}
                        </List>
                    );
                })}
            </Box>
            <Divider/>
            <Box>
                <Typography variant="h6" sx={{display: 'flex', justifyContent: 'space-between', padding: 1}}>
                    <span>Full price:</span>
                    <span style={{
                        color: "#ea5b3a",
                        fontWeight: "bold",
                    }}>{bookingPrices.fullPriceFormatted}</span>
                </Typography>
            </Box>
        </Drawer>
    );
};

export default SummaryDrawer;
import React, {useContext, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import IndexPage from "./pages/IndexPage";
import {ThemeProvider} from "@mui/material";
import {theme} from "./utils/theme";
import SuccessfulBookingPage from "./pages/SuccessfulBookingPage";

export const SnackContext = React.createContext();
export const DrawerContext = React.createContext({
    isDrawerOpen: false
});

export const BookingPriceContext = React.createContext({
});
export const ErrorContext = React.createContext();

const App = () => {
    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [bookingPrices, setBookingPrices] = useState({
        fullPrice: 0,
        fullPriceFormatted: "",
        rooms: [],
        arrivalDateFormatted: "",
        departureDateFormatted: "",
        timeBlock: "",
    });
    const [msg, setMsg] = useState("Something went wrong");
    const [errors, setErrors] = useState([]);
    const [severity, setSeverity] = useState("error");

    return (
        <ErrorContext.Provider value={{errors, setErrors}}>
            <SnackContext.Provider
                value={{
                    isSnackOpen,
                    setIsSnackOpen,
                    msg,
                    setMsg,
                    severity,
                    setSeverity,
                }}
            >
                <DrawerContext.Provider value={{isDrawerOpen, setDrawerOpen}}>
                    <BookingPriceContext.Provider value={{bookingPrices, setBookingPrices}}>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<IndexPage/>}/>
                                        <Route path="/successful-booking" element={<SuccessfulBookingPage/>}/>
                                    </Routes>
                                </BrowserRouter>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </BookingPriceContext.Provider>
                </DrawerContext.Provider>
            </SnackContext.Provider>
        </ErrorContext.Provider>
    );
};

export default App;

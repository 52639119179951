import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import {Box, Container} from "@mui/system";
import {Alert, Grid} from "@mui/material";

const SuccessfulBookingPage = () => {
    return (
        <main style={{overflow: "hidden"}}>
            <Header/>
            <Hero title="Successful booking"/>
            <Container maxWidth="md">
                <Grid container spacing={2} mb={10}>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            <Alert variant="filled" severity="success">Thank you, your booking has been placed successfully! Check your mailbox for further details!</Alert>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Footer/>
        </main>
    );
};

export default SuccessfulBookingPage;
export const getName = (id, items) => {
  if (!items || items.length === 0) return "Cannot find name";

  const item = items.find((i) => i._id === id);

  if (!item) return "Cannot find name ";

  return item.title;
};

export const getRoomName = (id, rooms) => {
  if (!rooms || rooms.length === 0) return "Cannot find room";

  const item = rooms.find((rObj) => rObj.room._id === id);

  if (!item) return "Cannot find room";

  return item.room.title;
};

export const getAddonNames = (ids, addons) => {
  const list = [];

  if (!ids || !addons) return;

  if (ids.length === 0) return " - ";

  ids.forEach((id) => {
    addons.forEach((addon) => {
      if (addon.addon._id === id) {
        list.push(addon.addon.title);
      }
    });
  });

  return list.join(", ");
};

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import StarsRating from "react-star-rate";

// url("https://solidsurfhouse.com/wp-content/uploads/2022/01/pexels-oliver-sjostrom-1005417_edit-scaled.jpg")
const useStyles = makeStyles(
  () => ({
    footer: {
      background:
        "url(https://solidsurfhouse.com/wp-content/uploads/2022/01/pexels-oliver-sjostrom-1005417_edit-scaled.jpg)",
      paddingTop: 40,
      paddingBottom: 40,
      "& ul": {
        padding: 0,
        listStyleType: "none",
      },
      "& li": {
        marginBottom: 16,
      },
      "& a": {
        textDecoration: "none",
        color: "#fff",
      },
      "& p": {
        color: "#fff",
      },
      "& h6": {
        color: "#fff",
      },
    },
    logo: {
      width: "80%",
      margin: "24px auto",
      display: "block",
    },
    title: {
      borderBottom: "1px solid white",
    },
  }),
  { name: "Footer" }
);

const Footer = () => {
  const classes = useStyles({});
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container maxWidth={"lg"} spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title}>
              Quick Links Prices
            </Typography>

            <ul>
              <li>
                <a
                  style={{ color: "#ea5b3a" }}
                  href="https://solidsurfhouse.com/surfcamp/surf-camp-bali/#room"
                >
                  Indonesia | Bali – Canggu
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#ea5b3a" }}
                  href="https://solidsurfhouse.com/surfcamp/morocco/#room"
                >
                  Morocco | Taghazout Bay
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#ea5b3a" }}
                  href="https://solidsurfhouse.com/surfcamp/surf-camp-sri-lanka/"
                >
                  Sri Lanka | Weligama Bay
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#ea5b3a" }}
                  href="https://solidsurfhouse.com/surfcamp/arugambay-srilanka/"
                >
                  Sri Lanka | Arugam Bay
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#ea5b3a" }}
                  href="https://solidsurfhouse.com/surfcamp/caparica-portugal/#room"
                >
                  Portugal | Costa Caparica
                </a>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title}>
              Bookings
            </Typography>

            <ul>
              <li>
                <a href="mailto:Bali@solidsurfhouse.com">
                  Bali@solidsurfhouse.com
                </a>
              </li>
              <li>
                <a href="mailto:Morocco@solidsurfhouse.com">
                  Morocco@solidsurfhouse.com
                </a>
              </li>
              <li>
                <a href="mailto:Srilanka@solidsurfhouse.com">
                  Srilanka@solidsurfhouse.com
                </a>
              </li>
              <li>
                <a href="mailto:Srilanka@solidsurfhouse.com">
                  Portugal@solidsurfhouse.com
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title}>
              Head Office
            </Typography>

            <ul>
              <li>
                <a href="mailto:Holiday@solidsurfhouse.com">
                  Holiday@solidsurfhouse.com
                </a>
              </li>
            </ul>

            <Typography variant="h6" className={classes.title}>
              Marketing & Media
            </Typography>
            <ul>
              <li>
                <a href="mailto:Media@solidsurfhouse.com">
                  Media@solidsurfhouse.com
                </a>
              </li>
            </ul>
            <Typography variant="h6" className={classes.title}>
              Jobs & B2B
            </Typography>
            <ul>
              <li>
                <a href="mailto:Holiday@solidsurfhouse.com">
                  Holiday@solidsurfhouse.com
                </a>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="body1" textAlign="center">
              Reviewed by 18987 visitors
            </Typography>

            <StarsRating
              value={4.8}
              style={{
                style: { fontSize: 24, width: "100%", textAlign: "center" },
                full: {
                  first: { color: "#EA5B3A" },
                  second: { color: "#EA5B3A" },
                },
                half: {
                  first: { color: "#EA5B3A" },
                },
              }}
            />

            <img
              alt="Solid surf house"
              className={classes.logo}
              src="https://solidsurfhouse.com/wp-content/uploads/2022/02/Solidlogo-300x300.png"
              loading="lazy"
              srcSet="https://solidsurfhouse.com/wp-content/uploads/2022/02/Solidlogo-300x300.png 300w, https://solidsurfhouse.com/wp-content/uploads/2022/02/Solidlogo-100x100.png 100w, https://solidsurfhouse.com/wp-content/uploads/2022/02/Solidlogo-150x150.png 150w, https://solidsurfhouse.com/wp-content/uploads/2022/02/Solidlogo.png 593w"
            ></img>

            <a href="https://solidsurfhouse.com/terms-conditions">
              <span
                style={{
                  color: "#ea5b3a",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                TERMS &amp; CONDITIONS
              </span>
            </a>

            <Typography
              variant="caption"
              textAlign="center"
              color="white"
              display="block"
            >
              All Rights Reserved by Solid Holiday Services Ltd. ® 2014 – 2022
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;

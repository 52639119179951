import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { connect, FieldArray, getIn } from "formik";
import React, { useEffect, useState } from "react";
import axios from "../utils/axios";

const useStyles = makeStyles(
  () => ({
    guestWrapper: {
      padding: 16,
      background: "#fcfcfc",
      border: "1px solid rgba(0, 0, 0, 0.06)",
    },
  }),
  { name: "Step3" }
);

const Step2 = ({ formik }) => {
  const classes = useStyles({});
  const [surfLevels, setSurfLevels] = useState([]);
  const [dietTypes, setDietTypes] = useState([]);
  const [warningOpen, setWarningOpen] = useState(true);

  const fetchGuestProperties = () => {
    axios
      .get("/guest-properties")
      .then(function (response) {
        if (!response.data) return;

        setSurfLevels(response.data.data.surf_levels);
        setDietTypes(response.data.data.diet_types);

        formik.setFieldValue("surf_levels", response.data.data.surf_levels);
        formik.setFieldValue("diet_types", response.data.data.diet_types);

        // setDestinations(response.data.data);
      })
      .catch(function (error) {
        // setOpen(true);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 500, left: 0, behavior: "smooth" });
    fetchGuestProperties();
  }, []);

  const renderGuest = (
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
    remove,
    g,
    index
  ) => {
    const first_name = `guests[${index}].first_name`;
    const touchedFirstName = getIn(touched, first_name);
    const errorFirstName = getIn(errors, first_name);

    const last_name = `guests[${index}].last_name`;
    const touchedLastName = getIn(touched, last_name);
    const errorLastName = getIn(errors, last_name);

    const email = `guests[${index}].email`;
    const touchedEmail = getIn(touched, email);
    const errorEmail = getIn(errors, email);

    const surfLevel = `guests[${index}].surf_level`;
    const touchedSurfLevel = getIn(touched, surfLevel);
    const errorSurfLevel = getIn(errors, surfLevel);

    const dietType = `guests[${index}].diet_type`;
    const touchedDietType = getIn(touched, dietType);
    const errorDietType = getIn(errors, dietType);

    return (
      <Box mb={3} key={index} className={classes.guestWrapper}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="h5" mr={1}>
              Guest {index + 1}
            </Typography>

            {/* {!warningOpen && (
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setWarningOpen(true);
                }}
              >
                <InfoOutlinedIcon fontSize="inherit" color="#757575" />
              </IconButton>
            )} */}
          </Box>
          {index === 0 && (
            <Button
              onClick={() => {
                setFieldValue(first_name, values.first_name);
                setFieldValue(last_name, values.last_name);
                setFieldValue(email, values.email);
              }}
              size="small"
            >
              Same as booker
            </Button>
          )}
        </Box>

        <Grid container mb={1} spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="First name"
              name={first_name}
              value={g.first_name}
              required
              helperText={
                touchedFirstName && errorFirstName ? errorFirstName : ""
              }
              error={Boolean(touchedFirstName && errorFirstName)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="Last name"
              name={last_name}
              value={g.last_name}
              required
              helperText={touchedLastName && errorLastName ? errorLastName : ""}
              error={Boolean(touchedLastName && errorLastName)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="surfLevel-select-label" required>
                Surf level
              </InputLabel>
              <Select
                name={surfLevel}
                labelId="surfLevel-select-label"
                id="surfLevel-select"
                value={g.surf_level}
                // onChange={formik.handleChange}
                onChange={(e) =>
                  formik.setFieldValue(surfLevel, e.target.value, true)
                }
                error={touchedSurfLevel && Boolean(errorSurfLevel)}
                required
              >
                {surfLevels.map((o) => (
                  <MenuItem value={o._id} key={o._id}>
                    {o.title}
                  </MenuItem>
                ))}
              </Select>

              {touchedSurfLevel && Boolean(errorSurfLevel) && (
                <FormHelperText error>{errorSurfLevel}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="dietType-select-label" required>
                Diet type
              </InputLabel>
              <Select
                name={dietType}
                labelId="dietType-select-label"
                id="dietType-select"
                value={g.diet_type}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.setFieldValue(dietType, e.target.value, true);
                }}
                error={touchedDietType && Boolean(errorDietType)}
                required
              >
                {dietTypes.map((o) => (
                  <MenuItem value={o._id} key={o._id}>
                    {o.title}
                  </MenuItem>
                ))}
              </Select>

              {touchedDietType && Boolean(errorDietType) && (
                <FormHelperText error>{errorDietType}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Box mb={1} mt={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Email"
            name={email}
            value={g.email}
            required
            helperText={
              touchedEmail && errorEmail
                ? errorEmail
                : "Please fill out the email so each booker receives the travel information and their personal booking details."
            }
            error={Boolean(touchedEmail && errorEmail)}
            onChange={formik.handleChange}
            onBlur={handleBlur}
          />
        </Box>

        {index !== 0 && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              margin="normal"
              type="button"
              color="error"
              variant="outlined"
              size="small"
              onClick={() => remove(index)}
              startIcon={<DeleteIcon />}
            >
              Remove guest
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box mb={2}>
        <Collapse in={warningOpen}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWarningOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            "Note: You have to add the Booker as a guest too."
          </Alert>
        </Collapse>
      </Box>

      <Box mb={2}>
        <Collapse in={warningOpen}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWarningOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            "Note: All fields marked with a cross (*) are mandatory."
          </Alert>
        </Collapse>
      </Box>

      <FieldArray name="guests">
        {({ push, remove }) => (
          <div>
            {formik.values.guests.map((g, index) => {
              return renderGuest(
                formik.values,
                formik.touched,
                formik.errors,
                formik.handleChange,
                formik.handleBlur,
                formik.isValid,
                formik.setFieldValue,
                remove,
                g,
                index
              );
            })}
            <Button
              className={classes.button}
              type="button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                push({
                  id: Math.random(),
                  first_name: "",
                  last_name: "",
                  email: "",
                  addons: [],
                  roomId: null,
                  surf_level: null,
                  diet_type: null,
                })
              }
            >
              Add
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default connect(Step2);

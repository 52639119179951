import React, { useContext } from "react";

import Dashboard from "../pages/Dashboard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackContext } from "../App";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IndexPage = () => {
  const { isSnackOpen, setIsSnackOpen, msg, severity } =
    useContext(SnackContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackOpen(false);
  };

  return (
    <main style={{ overflow: "hidden" }}>
      <Header />
      <Hero title="Book now" />
      <Dashboard />
      <Footer />

      <Snackbar
        open={isSnackOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default IndexPage;

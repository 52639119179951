import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles(
  () => ({
    hero: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background:
        "url(https://images.unsplash.com/photo-1526485856375-9110812fbf35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 600,
    },
  }),
  { name: "Hero" }
);

const Hero = ({title}) => {
  const classes = useStyles({});

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={12}
      className={classes.hero}
    >
      <Typography variant="h1" color={"#FFF"}>
        {title}
      </Typography>
    </Box>
  );
};

export default Hero;

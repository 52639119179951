import { Button, Step, StepLabel, Stepper } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import moment from "moment";
import React, {useContext, useRef, useState} from "react";
import axios from "../utils/axios";
import {BookingPriceContext, DrawerContext} from "../App";

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.

const Wizard = ({
  setIsSnackOpen,
  setMsg,
  children,
  initialValues,
  onSubmit
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const { setBookingPrices } = useContext(BookingPriceContext);
  const { setDrawerOpen } = useContext(DrawerContext);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const formikRef = useRef(null);

  const getValuesForValidation = (values) => {
    const arrivalString = moment(values.arrival_date).format("YYYY-MM-DD");
    const departureString = moment(values.departure_date).format("YYYY-MM-DD");

    let data = {
      arrival_date: arrivalString,
      departure_date: departureString,
      destination: values.location,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      country: values.country,
      city: values.city,
      address: values.address,
      id_or_passport: values.id_or_passport,
      emergency_name: values.emergency_name,
      emergency_email: values.emergency_email,
      emergency_phone: values.emergency_phone,
    };

    let guests = [...values.guests];
    guests = JSON.parse(JSON.stringify(guests));
    guests.forEach((g) => {
      delete g.id;
      delete g.addons;
      delete g.roomId;
    });

    let rooms = [];
    let roomIds = [];
    let rGuests = [...values.guests];
    rGuests = JSON.parse(JSON.stringify(rGuests));
    rGuests.forEach((g) => {
      if (!roomIds.includes(g.roomId)) {
        roomIds.push(g.roomId);
      }
    });

    roomIds.forEach((rId) => {
      const arr = [];

      rGuests.forEach((g) => {
        if (g.roomId === rId) {
          arr.push(g);
        }
      });
      const obj = { room: rId, guests: arr };
      rooms.push(obj);
    });

    if (stepNumber === 1) {
      data = {
        ...data,
        guests: JSON.stringify(guests),
      };
    }

    if (stepNumber === 2) {
      let rooms = [];
      let roomIds = [];
      const rGuests = [...values.guests];
      rGuests.forEach((g) => {
        if (!roomIds.includes(g.roomId)) {
          roomIds.push(g.roomId);
        }
      });

      roomIds.forEach((rId) => {
        const arr = [];

        rGuests.forEach((g) => {
          if (g.roomId === rId) {
            arr.push(g);
          }
        });
        const obj = { room: rId, guests: arr };
        rooms.push(obj);
      });

      data = {
        ...data,
        guests: JSON.stringify(guests),
        rooms: JSON.stringify(rooms),
      };
    }

    if (stepNumber === 3) {
      data = {
        ...data,
        guests: JSON.stringify(guests),
        rooms: JSON.stringify(rooms),
        approve_booking: values.approve_booking,
        register_confirm: values.register_confirm,
        tc_confirm: values.tc_confirm,
        tax_confirm: values.tax_confirm,
      };
    }

    return data;
  };

  const next = async (values) => {
    const stepData = getValuesForValidation(values);

    let formData = new FormData();
    Object.keys(stepData).forEach((key) => formData.append(key, stepData[key]));

    try {
      const response = await axios({
        method: "post",
        url: `/validate-step?step=${stepNumber + 1}`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.data && !response.data.data.hasError) {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
        setBookingPrices(response.data.data);
        setDrawerOpen(false);
      } else {
        const errors = response.data.data.errors;
        let fieldNames = "";

        Object.keys(errors).map((key) => (fieldNames += ` ${key}`));
        setIsSnackOpen(true);
        setMsg("Please check these fields: " + fieldNames);
      }
    } catch (error) {
      setIsSnackOpen(true);
      setMsg("Something went wrong");
    }
  };

  const previous = (values) => {
    setDrawerOpen(false);
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  const renderLabel = (index) => {
    let label = "";
    switch (index) {
      case 0:
        label = "Basic Data";
        break;
      case 1:
        label = "Guests";
        break;
      case 2:
        label = "Rooms";
        break;
      case 3:
        label = "Summary";
        break;
      default:
        break;
    }

    if (isSmallScreen) return "";

    return label;
  };

  return (
    <>
      <Box mb={10}>
        <Stepper activeStep={stepNumber}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{renderLabel(index)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => {
          return (
            <Box sx={{ width: "100%" }}>
              <Form noValidate>
                {step}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {stepNumber > 0 && (
                    <Button
                      color="inherit"
                      disabled={stepNumber === 0}
                      onClick={() => previous(formik.values)}
                    >
                      Back
                    </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button disabled={formik.isSubmitting} type="submit">
                    {isLastStep ? "Submit" : "Next"}
                  </Button>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default Wizard;

import {Grid, Drawer} from "@mui/material";
import Box from "@mui/material/Box";
import {Container} from "@mui/system";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import {SnackContext} from "../App";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import Step4 from "../components/Step4";
import Wizard from "../components/Wizard";
import axios from "../utils/axios";
import {countries} from "../utils/countries";
import {useNavigate} from "react-router-dom";
import SummaryDrawer from "../components/SummaryDrawer";

const WizardStep = ({children}) => children;

const baseDataValidation = Yup.object().shape({
    destination: Yup.string().required("Destination is required"),
    location: Yup.string().required("Location is required"),
    arrival_date: Yup.date()
        .min(new Date(), "Please choose future date")
        .required("Arrival date is required"),
    departure_date: Yup.date()
        .min(new Date(), "Please choose future date")
        .required("Departure date is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    email: Yup.string()
        .email("Must be a valid email address")
        .required("Email is required"),
    phone: Yup.mixed().required("Phone is required"),
    country: Yup.string().required("Country is required"),
    id_or_passport: Yup.mixed().required("Passport or ID is required"),
    emergency_name: Yup.mixed().required("Emergency contact name is required"),
    emergency_email: Yup.mixed().required("Emergency email is required"),
    emergency_phone: Yup.mixed().required("Emergency phone is required"),
});

const guestsValidationSchema = Yup.object().shape({
    guests: Yup.array().of(
        Yup.object().shape({
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            email: Yup.string()
                .email("Must be a valid email")
                .required("Guest's email is required")
                .nullable(),
            surf_level: Yup.string().required("Surf level is required"),
            diet_type: Yup.string().required("Diet type is required"),
            addons: Yup.array().of(Yup.string()),
        })
    ),
});

const roomValidationSchema = Yup.object().shape({
    filteredGuestsLength: Yup.number().test(
        "val",
        "You have to assign everybody to a room",
        (val) => val.toString() === "0"
    ),
});

const confirmValidation = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    email: Yup.string()
        .email("Must be a valid email address")
        .required("Email is required"),
    phone: Yup.mixed().required("Phone is required"),
    country: Yup.string().required("Country is required"),
    approve_booking: Yup.boolean().oneOf(
        [true],
        "You must apply that this is a booking and not a inquiry!"
    ),
    register_confirm: Yup.boolean().oneOf(
        [true],
        "You must apply that you'll be registered into our loyalty system!"
    ),
    tc_confirm: Yup.boolean().oneOf([true], "You must apply our T&C!"),
    tax_confirm: Yup.boolean().oneOf([true], "You must apply our taxes!"),
});

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const initialValues = {
    destination: "",
    location: "",
    arrival_date: new Date(),
    departure_date: tomorrow,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    emergency_name: "",
    emergency_email: "",
    emergency_phone: "",
    address: "",
    city: "",
    country: "",
    id_or_passport: null,
    guests: [
        {
            id: Math.random(),
            first_name: "",
            last_name: "",
            email: "",
            surf_level: "",
            diet_type: "",
            addons: [],
            roomId: null,
        },
    ],
    rooms: [],
    approve_booking: true,
    register_confirm: true,
    tc_confirm: true,
    tax_confirm: true,
};

const Dashboard = () => {
    const [destinations, setDestinations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const {setIsSnackOpen, setMsg, setSeverity} = useContext(SnackContext);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get("/country")
            .then(function (response) {
                if (!response.data) return;

                setDestinations(response.data.data);
            })
            .catch(function (error) {
                setIsSnackOpen(true);
            });
    }, []);

    const createBooking = async (values) => {
        let booking = {...values};
        let guests = [...values.guests];
        guests = JSON.parse(JSON.stringify(guests));

        booking.destination = values.location;
        delete booking.location;

        let rooms = [];
        let roomIds = [];

        let rGuests = [...values.guests];
        rGuests = JSON.parse(JSON.stringify(guests));
        rGuests.forEach((g) => {
            if (!roomIds.includes(g.roomId)) {
                roomIds.push(g.roomId);
            }
        });

        roomIds.forEach((rId) => {
            const arr = [];

            rGuests.forEach((g) => {
                if (g.roomId === rId) {
                    arr.push(g);
                }
            });
            const obj = {room: rId, guests: arr};
            rooms.push(obj);
        });

        guests.forEach((g) => {
            delete g.id;
            delete g.addons;
            delete g.roomId;
        });

        const arrivalString = moment(values.arrival_date).format("YYYY-MM-DD");
        const departureString = moment(values.departure_date).format("YYYY-MM-DD");

        rooms.forEach((room) => {
            room.guests.forEach((gu) => {
                delete gu.id;
                delete gu.roomId;
            });
        });
        booking.guests = JSON.stringify(guests);
        booking.rooms = JSON.stringify(rooms);
        booking.arrival_date = arrivalString;
        booking.departure_date = departureString;
        delete booking.filteredGuestsLength;
        delete booking.addons;
        delete booking.diet_types;
        delete booking.surf_levels;

        // console.log("BOOKING: ", booking);

        let formData = new FormData();
        Object.keys(booking).forEach((key) => formData.append(key, booking[key]));

        try {
            const response = await axios({
                method: "post",
                url: `/book`,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            });

            if (response.data.status) {
                navigate('/successful-booking');
            } else {
                setSeverity("error");
                setIsSnackOpen(true);
            }
        } catch (error) {
            setSeverity("error");
            setIsSnackOpen(true);
            setMsg("Something went wrong");
        }
    };

    return (
        <Container maxWidth="md">
            <SummaryDrawer />
            
            <Grid container spacing={2} mb={10}>
                <Grid item xs={12}>
                    <Box sx={{width: "100%"}}>
                        <Wizard
                            setIsSnackOpen={setIsSnackOpen}
                            setMsg={setMsg}
                            initialValues={initialValues}
                            onSubmit={async (values) => {
                                createBooking(values);
                            }}
                        >
                            <WizardStep
                                onSubmit={() => {
                                }}
                                validationSchema={baseDataValidation}
                            >
                                <Step1
                                    countries={countries}
                                    destinations={destinations}
                                    onLocationSelected={(location) =>
                                        setSelectedLocation(location)
                                    }
                                />
                            </WizardStep>
                            <WizardStep
                                onSubmit={() => {
                                    // console.log("Step3 onSubmit");
                                }}
                                validationSchema={guestsValidationSchema}
                            >
                                <Step2/>
                            </WizardStep>
                            <WizardStep
                                onSubmit={() => {
                                    // console.log("Step3 onSubmit");
                                }}
                                validationSchema={roomValidationSchema}
                            >
                                <Step3 selectedLocation={selectedLocation}/>
                            </WizardStep>
                            <WizardStep
                                onSubmit={() => {
                                    // console.log("Step4 onSubmit");
                                }}
                                validationSchema={confirmValidation}
                            >
                                <Step4 />
                            </WizardStep>
                        </Wizard>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;

import { Button, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React from "react";

const useStyles = makeStyles(
  () => ({
    headerWrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      background: "rgba(0,0,0,.7)",
      zIndex: 10,
      backdropFilter: "blur(10px)",
    },
    header: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 16,
      boxSizing: "border-box",
    },
    logoWrapper: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: 60,
      },
    },
    menu: {},
  }),
  { name: "Footer" }
);

const Header = () => {
  const classes = useStyles({});
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box className={classes.headerWrapper}>
      <header className={classes.header}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} display="flex" alignItems="center">
              <Button
                size={isSmallScreen ? "small" : "normal"}
                variant="contained"
                onClick={() => {
                  window.location.href = "";
                }}
              >
                DESTINATIONS
              </Button>
              <div className={classes.logoWrapper}>
                <img
                  src="https://solidsurfhouse.com/wp-content/uploads/elementor/thumbs/Solidlogo-pk6m9gbhnrvknw9l1o20utziwemvzka3vsx29kb8d2.png"
                  title="Solidlogo"
                  alt="Solidlogo"
                />
              </div>

            </Grid>
          </Grid>
        </Container>
      </header>
    </Box>
  );
};

export default Header;

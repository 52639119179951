import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#EA5B3A",
    },
    secondary: {
      main: "#3ac9ea",
    },
    text: {
      primary: "#0e0e2c",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: "#f8f8f8",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        shrink: {
          background: "#ffffff"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          background: "#ffffff"
        }
      }
    }
  },
});

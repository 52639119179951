import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/styles";
import {connect} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {countries} from "../utils/countries";
import {getAddonNames, getName, getRoomName} from "../utils/nameUtils";
import {BookingPriceContext, DrawerContext} from "../App";

const Input = styled("input")({
    display: "none",
});

const Step4 = ({formik}) => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const { bookingPrices } = useContext(BookingPriceContext);
    const { setDrawerOpen } = useContext(DrawerContext);

    setDrawerOpen(true);

    useEffect(() => {
        window.scrollTo({top: 500, left: 0, behavior: "smooth"});
    }, []);

    return (
        <Box>
            <Typography variant="h5" mb={3}>
                Summary
            </Typography>

            <Box mb={3}>
                <Box>
                    <Typography variant="h6" mb={1}>
                        Booker details
                    </Typography>

                    <Grid container mb={3} spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                id="first_name"
                                name="first_name"
                                label="Firstname"
                                variant="outlined"
                                type="text"
                                required
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.first_name}
                                error={
                                    formik.touched.first_name && Boolean(formik.errors.first_name)
                                }
                                helperText={
                                    formik.touched.first_name && formik.errors.first_name
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="lastName"
                                name="last_name"
                                label="Lastname"
                                variant="outlined"
                                type="text"
                                required
                                fullWidth
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.last_name && Boolean(formik.errors.last_name)
                                }
                                helperText={formik.touched.last_name && formik.errors.last_name}
                            />
                        </Grid>
                    </Grid>
                    <Box mb={3}>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                type="email"
                                required
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </FormControl>
                    </Box>
                    <Box mb={3}>
                        <FormControl fullWidth>
                            <TextField
                                id="address"
                                name="address"
                                label="Address"
                                variant="outlined"
                                type="text"
                                required
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </FormControl>
                    </Box>
                    <Box mb={3}>
                        <FormControl fullWidth>
                            <TextField
                                id="city"
                                name="city"
                                label="City"
                                variant="outlined"
                                type="text"
                                required
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                            />
                        </FormControl>
                    </Box>
                    <Box mb={6}>
                        <Autocomplete
                            name="country"
                            value={formik.values.country}
                            onChange={(e, value) => {
                                formik.setFieldValue("country", value.code, true);
                            }}
                            options={countries}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={
                                        formik.touched.country && Boolean(formik.errors.country)
                                    }
                                    helperText={formik.touched.country && formik.errors.country}
                                    label="Country"
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",
                                    }}
                                />
                            )}
                            required
                        />
                    </Box>

                    <Box mb={6}>
                        <Typography variant="h6" mb={1}>
                            Guests
                        </Typography>

                        <TableContainer>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Room</TableCell>
                                        <TableCell align="left">Surf level</TableCell>
                                        <TableCell align="left">Diet type</TableCell>
                                        <TableCell align="left">Addons</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formik.values.guests.map((g) => (
                                        <TableRow
                                            key={`${g.first_name} ${g.last_name}`}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {`${g.first_name} ${g.last_name}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {g.email || " - "}
                                            </TableCell>
                                            <TableCell align="left">
                                                {getRoomName(g.roomId, formik.values.rooms)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {" "}
                                                {getName(g.surf_level, formik.values.surf_levels)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {getName(g.diet_type, formik.values.diet_types)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {getAddonNames(g.addons, formik.values.addons)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Box mb={6}>
                        <Typography variant="h6" mb={2}>
                            Summarized price (estimated *)
                        </Typography>
                        <Typography variant="h4" color="primary" mb={2}>{bookingPrices.fullPriceFormatted}</Typography>
                        <Typography variant="body2" mb={1}>
                            * Please note that this is an estimated price. Final price will be calculated during the
                            processing of your booking.
                        </Typography>
                    </Box>
                    <Box mb={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="comment"
                                name="comment"
                                label="Comment"
                                variant="outlined"
                                type="text"
                                multiline
                                rows={10}
                            />
                        </FormControl>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        position="relative"
                    >
                        <FormControl fullWidth>
                            <label htmlFor="contained-button-voucher">
                                <Input
                                    accept="image/*"
                                    id="contained-button-voucher"
                                    name="voucher"
                                    type="file"
                                    onChange={(event) => {
                                        const f = event.target.files[0];
                                        setFile(f);

                                        formik.setFieldValue("voucher", f, true);

                                        var reader = new FileReader();

                                        reader.onload = function (e) {
                                            setPreview(e.target.result);
                                        };

                                        reader.readAsDataURL(event.target.files[0]);
                                    }}
                                />
                                <Button variant="contained" component="span">
                                    Upload Voucher
                                </Button>
                            </label>
                        </FormControl>
                        {(preview || file) && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                style={{maxWidth: 300}}
                            >
                                <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    size="small"
                                    style={{
                                        position: "absolute",
                                        top: 8,
                                        right: 8,
                                        background: "white",
                                    }}
                                    onClick={() => {
                                        formik.setFieldValue("voucher", null, true);
                                        setPreview(null);
                                        setFile(null);
                                    }}
                                >
                                    <DeleteIcon/>
                                </IconButton>

                                {preview && (
                                    <img
                                        src={preview}
                                        style={{maxWidth: 300, borderRadius: 4}}
                                        alt="Uploaded ID or passport"
                                    />
                                )}

                                {file && <Typography variant="caption">{file.name}</Typography>}
                            </Box>
                        )}
                    </Box>

                    <Box mb={5}/>
                    <Typography variant="h5" mb={1}>
                        Accept Booking Conditions
                    </Typography>

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked/>}
                            label="I accept that the booking is not an approved reservation yet"
                            name="approve_booking"
                            onChange={(e, v) => {
                                formik.setFieldValue("approve_booking", v, true);
                            }}
                        />
                        {Boolean(formik.errors.approve_booking) && (
                            <FormHelperText error>
                                {formik.errors.approve_booking}
                            </FormHelperText>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked/>}
                            label="I accept that I will be registered in the booking system of Solid Surfhouse"
                            name="register_confirm"
                            onChange={(e, v) => {
                                formik.setFieldValue("register_confirm", v, true);
                            }}
                        />

                        {Boolean(formik.errors.register_confirm) && (
                            <FormHelperText error>
                                {formik.errors.register_confirm}
                            </FormHelperText>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked/>}
                            label="I accept the terms and conditions"
                            name="tc_confirm"
                            onChange={(e, v) => {
                                formik.setFieldValue("tc_confirm", v, true);
                            }}
                        />
                        {Boolean(formik.errors.tc_confirm) && (
                            <FormHelperText error>{formik.errors.tc_confirm}</FormHelperText>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked/>}
                            label="I accept to pay 1€ per day stay tax in accordance with local law"
                            name="tax_confirm"
                            onChange={(e, v) => {
                                formik.setFieldValue("tax_confirm", v, true);
                            }}
                        />
                        {Boolean(formik.errors.tax_confirm) && (
                            <FormHelperText error>{formik.errors.tax_confirm}</FormHelperText>
                        )}
                    </FormGroup>
                </Box>
            </Box>
        </Box>
    );
};

export default connect(Step4);
